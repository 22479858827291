import React, { useState } from "react";
import styled from "styled-components";
import {
  IoLockClosed,
  IoTimer,
  IoFlash,
  IoPeople,
  IoPlay,
  IoCalculator,
  IoSunny,
  IoPaperPlane,
  IoPhonePortrait,
  IoPricetag,
} from "react-icons/io5";

import { useModal } from "../../components/useModal";

import {
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
  Button,
} from "../../components/util";
import {
  CustomerQuote,
  Modal,
  ProductPricing,
  ImageGraphic,
  Feature,
} from "../../components/site/";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime_Horiz_White.svg";

const HeroCircle = styled.div`
  height: 350px;
  background: ${(props) => props.theme.colours.Primary};
  opacity: 0.2;
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 50px;
  border-radius: 400px 400px 0 0;
`;

const PlayCircle = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-decoration: none;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: ${(props) => props.theme.styles.shadow};
  transition: all ${(props) => props.theme.transitions.med};

  &:hover {
    height: 110px;
    width: 110px;
  }

  svg {
    font-size: 40px;
    color: ${(props) => props.theme.colours.dark};
  }
`;

const FromFlexiTimeBadge = styled.img`
  z-index: 99;
  height: 20px;
`;

const PayrollLP = (props) => {
  const [calendlySource, setCalendlySource] = useState("");
  const [videoModal, setVideoModal] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useModal();

  function handleModal(url, video) {
    setItemModalOpen(true);
    setCalendlySource(url);
    setVideoModal(video);
  }

  return (
    <Layout fullPage>
      <Seo
        title="Online Payroll Software for NZ Businesses | PayHero"
        description="Take care of your team on payday with smart, modern and seriously compliant online payroll for NZ businesses. Get started today."
        pathname={props.location.pathname}
      />
      <Container css={{ position: "sticky", top: "0", zIndex: "999" }}>
        <Wrapper stackGap={10}>
          <Row alignCenter noBreak>
            <img
              src={PayHeroLogo}
              alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
              css={{ maxWidth: "220px", maxHeight: "30px" }}
            />
            <Button className="primary -md gtm-cta" to="/signup">
              Start Free Trial
            </Button>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Box size={100} stackGap={40} centerOnMobile>
            <Box stackGap={10} className="-textCenter">
              <h1 className="h1 -fontLarge">
                Pay employees right with <br className="hideOnMobile" />
                <span className="-fontPrimary">
                  stress-free payroll & timesheets
                </span>
              </h1>
              <h3 className="-fontNormal" css={{ color: "#333" }}>
                Take care of your team on payday with smart, modern and
                seriously compliant online payroll software.
              </h3>
            </Box>
            <Box stackGap={20}>
              <FlexButtons justify="center">
                {/* <Button className="primary -xl gtm-cta" to="/signup">
                  Try Payroll for Free
                </Button> */}
                <Button className="primary -xl gtm-cta" to="/signup">
                  Start 14 Day Free Trial
                </Button>
              </FlexButtons>
              {/* <p className="-textCenter" css={{ color: "#999" }}>
                Talk to the team to learn how PayHero can help your business.
              </p> */}
            </Box>
          </Box>
          <HeroCircle className="hideOnMobile" />
          <Box>
            <Image
              filename="PayHeroHero_Cropped_Manufacturing.png"
              alt="PayHero"
              maxWidth={1000}
              centerImage
              addShadow
            />
            <PlayCircle
              href="#!"
              onClick={() => {
                handleModal(
                  "https://player.vimeo.com/video/369453779?autoplay=1",
                  true
                );
              }}
            >
              <IoPlay />
            </PlayCircle>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoLockClosed />
                <br />
                Payroll compliance made easy
              </h3>
              <p>
                Relax safe in the knowledge that your payroll system follows the
                very latest Holidays Act guidance from MBIE and legislative
                updates are automatically applied. Just business as usual as far
                as you’re concerned.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoFlash />
                <br />
                Fast, easy, accurate & stress-free
              </h3>
              <p>
                Pay your staff in minutes and let our powerful online payroll
                software do the hard work for you. Take care of all your wage
                and leave calculations, payslips and payday filing in a few
                clicks.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoPeople />
                <br />
                Payroll your employees can trust
              </h3>
              <p>
                Annual leave and holiday laws are complex, but we’ll make it
                easier to understand your obligations and for your staff to
                trust the entitlements and deductions in their pay.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={120}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Hospo.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Pay employees right with payroll software, built for
                  businesses like yours
                </h4>
              </Box>
              <Feature
                icon={<IoCalculator key={0} />}
                title="Spend less time on payroll"
                description="Paying your team shouldn’t take ages, so we’ve designed it to be easy. Pay your wages in just a few clicks. You can even put your payroll on Autopilot."
              />
              <Feature
                icon={<IoTimer key={0} />}
                title="Track time, don’t waste it"
                description="Experience the joy of smart time tools that automatically send online timesheets to PayHero."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Fly through Payday Filing"
                description="Automatically send your PAYE details to Inland Revenue after each pay."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="We've cut the time we spend doing payroll by 80%."
            name="Bridget Dunn"
            company="Prefab"
            pic="Prefab_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Employee mobile app
                </h2>
                <h4 className="h2">
                  Give your team the tools they need to complete key payroll
                  tasks from the palm of their hand
                </h4>
              </Box>
              <Feature
                icon={<IoPhonePortrait key={0} />}
                title="Timesheets in their pocket"
                description="From anywhere, employees can submit timesheets against different work items or cost centres by entering start, end and break times or using the GPS start/stop timer. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoPricetag key={0} />}
                title="Capture expenses with ease"
                description="Expense claims couldn’t be easier. Your employees can take a photo of a receipt, categorise it and submit the expense for approval from their phone."
              />
              <Feature
                icon={<IoSunny key={0} />}
                title="Easy leave requests"
                description="Employees can submit leave requests, check the approval status and balances and see a record of leave already taken and booked in with a simple calendar view."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Timesheets, leave, expenses & payslips on mobile"
                filename="PayHero_Mobile_Tradie.png"
                maxWidth={500}
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="We love the employee mobile app. It means we can all record time and keep track of our hours from wherever we’re working."
            name="Miranda Hitchings"
            company="Dignity"
            pic="Dignity_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2 className="h1">Pricing</h2>
              <h4>No Hidden Costs · Easy Set Up · Cancel Anytime</h4>
            </div>
            <ProductPricing selectedProduct="payhero" hideAddOns hideCTAs />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
          <CustomerQuote
            quote="I love the fact that it’s all online. These days I head home and do the wages with a glass of wine after dinner. The whole thing takes less than 10 minutes."
            name="Paul Newson"
            company="Brazen Clothing"
            pic="Brazen_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <Box stackGap={40}>
            <Box stackGap={7} className="-textCenter">
              <h2 className="h1">
                Manage payroll{" "}
                <span className="-fontPrimary">& pay employees right</span>
              </h2>
              <h3 css={{ color: "#333" }} className="-fontNormal">
                Talk to the team to see how PayHero can help your business.
              </h3>
            </Box>
            <FlexButtons justify="center">
              <Button className="primary -xl gtm-cta" to="/signup">
                Start 14 Day Free Trial
              </Button>
            </FlexButtons>
            {/* <p className="-textCenter">
              Talk to the team to learn how PayHero can help your business.
            </p> */}
          </Box>
          <FromFlexiTimeBadge className="-center" src={FromFlexiTime} />
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL={calendlySource}
        embedTitle="Introducing PayHero | Video"
        handleClose={() => setItemModalOpen(false)}
        isVideo={videoModal}
      />
    </Layout>
  );
};

export default PayrollLP;
